/**
 * Плавающий блок, привлекающий внимание. Используется, например, при добавлении товара в корзину.
 *
 * @example $('.add_to_cart').flyTo('#in_cart');
 * @desc Перемещение полупрозрачного блока от кнопки .add_to_cart до блока с содержимым корзины #in_cart за 300ms.
 *
 * @example $('.add_to_cart').flyTo({ target: '#in_cart', duration: 200, onFlyComplete:  function($this) {console.debug('Fly complete.');}});
 * @desc Перемещение полупрозрачного блока от кнопки .add_to_cart до блока с содержимым корзины #in_cart за 300ms, в полном формате.
 */
;(function ($) {
  $.fn.flyTo = function (options) {
    if (typeof options === 'string') {
      options = $.extend({ target: options }, $.fn.flyTo.defaults)
    } else {
      options = $.extend({}, $.fn.flyTo.defaults, options || {})
    }

    return this.each(function () {
      var $this = $(this)
      var $target = $(options.target)
      var whtl = { w: $this.width(), h: $this.height(), t: $this.offset().top, l: $this.offset().left }

      if (typeof options.onFlyStart === 'function') {
        options.onFlyStart($this)
      }

      $('<div></div>')
        .prependTo('body')
        .css({
          zIndex:           99999,
          backgroundColor:  'black',
          position :        'absolute',
          width:            whtl.w,
          height:           whtl.h,
          top:              whtl.t,
          left:             whtl.l,
          opacity:          0.6
        })
        .animate({
          opacity:  0.2,
          top:      $target.offset().top,
          left:     $target.offset().left,
          width:    $target.width(),
          height:   $target.height()
        }, options.duration, function () {
          $(this).remove()
          if (typeof options.onFlyComplete === 'function') {
            options.onFlyComplete($this)
          }
        })
    })
  }

  $.fn.flyTo.defaults = {
    duration:      300, // время пролёта элемента в милисекундах
    target:        '',  // элемент, до которого "летит" блок
    onFlyStart:    null, // вызывается перед началом перемещения
    onFlyComplete: null // callback method for when the element finishes updating
  }
})(window.jQuery)
